<script>
export default {
  data() {
    return {};
  },

  created() {
    this.$store.commit("Nav/SET_PAGE", "first_time");
    // this.$store.commit("Nav/SET_PAGE","first_time")
  },
};
</script>

<template>
  <div class="first-time-page">
    <!-- <div class="row justify-content-center"> -->
    <div class="welcome-text" id="text">
      <p>
        Welcome to your rewards shop, where you don't need any money to shop.<br />
        Earn points every time you order from us on Discogs, and spend those
        points here on rewards!<br />
      </p>

      <template v-if="$store.state.Auth.points">
        <p class="points-text mt-5">
          Your orders with us have earned you
          <span class="text-danger">{{ $store.state.Auth.points }}</span>
          points!
        </p>
      </template>
    </div>

    <router-link class="login-step-btn mt-3" :to="{ name: 'Home' }"
      >Show Me The Goods!</router-link
    >
  </div>
</template>

<style lang="scss" scoped>
@import "../assets/styles/_components.scss";
@import "../assets/styles/_utilities.scss";

.first-time-page {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  .welcome-text {
    white-space: pre-line;
    margin-top: 13.1rem;
    align-items: center;
    font-family: var(--baloo);
    font-size: 1.6rem;
    line-height: 3.3;
    text-align: center;
    width: 78rem;
  }
}
// .btn{
//     font-size: 16px;
//     border-radius: 3px;
// }
</style>
